<template>
  <div
    class="backdrop"
    @click.stop="$emit('close-modal')"
  >
    <div
      class="modal"
      @click.stop
      @mousedown.stop
    >
      <v-img
        src="../../assets/img/close-dark.svg"
        alt="close"
        class="close"
        @click.stop="$emit('close-modal')"
      />
      <div class="winner">
        <v-avatar
          min-width="64px"
          height="64px"
        >
          <v-img
            alt="Avatar"
            :src="photoUrl"
          />
        </v-avatar>
        <div class="winner-name">
          <p>
            {{ name }}
          </p>
        </div>
        <div class="nominations-container">
          <v-tooltip
            bottom
            max-width="200px"
            :top="true"
            :right="true"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  class="icon"
                  src="../../assets/img/star-green.svg"
                />
                <p>
                  {{ finalCount }}
                </p>
              </div>
            </template>
            <div>
              <span class="nomination-names">
                {{ nominationsNames }}
              </span>
              <span class="like-names">
                {{ likesNames }}
              </span>
            </div>
          </v-tooltip>
        </div>
      </div>
      <div class="separator" />
      <div class="nominations-list">
        <div class="nominations">
          <div
            v-for="nomination in nominationsReceived"
            :key="nomination.id"
            class="nomination-item"
          >
            <div class="avatar">
              <v-avatar
                min-width="40px"
                min-height="40px"
              >
                <v-img
                  alt="Avatar"
                  :src="nomination.user_who_nominated.photo_url"
                />
              </v-avatar>
            </div>
            <div class="nomination">
              <h1>{{ nomination.user_who_nominated.name }}</h1>
              <p>{{ nomination.justification }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '() => {}',
    },
    photoUrl: {
      type: String,
      default: '() => {}',
    },
    nominationsReceived: {
      type: Array,
      default: () => [],
    },
    likes: {
      type: Array,
      default: () => [],
    },
    finalCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['close-modal'],

  computed: {
    nominationsNames() {
      const names = this.nominationsReceived.map((nomination) => ` ${nomination.user_who_nominated.name}`);
      if (this.likes.length) {
        return `${names}, `;
      }
      return `${names}`;
    },
    likesNames() {
      const names = this.likes.map((like) => ` ${like.user.name}`);
      if (this.likes.length) {
        return `${names}`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: $modal-width;
  height: $modal-height;
  background-color: $modal-background;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.modal {
  padding: 16px;
  height: 356px;
  width: clamp(400px, 88%, 740px);
  background: $card-background;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 8px $shadow-green;
  position: relative;
  .separator {
    width: 0px;
    height: 80%;
    border: 1px solid $separator;
    opacity: 0.8;
  }
  .close {
    position: absolute;
    top: 16px;
    right: 28px;
    cursor: pointer;
    width: 20px;
  }
}

.winner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 40%;
  gap: 12px;

  .avatar {
    width: 64px;
    height: 64px;
  }

  .winner-name {
    font-weight: bold;
  }

  .nominations-container {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    display: flex;
    p {
      font: 16px/24px MontserratBold;
      margin: auto 0;
      opacity: 1;
    }
    .icon {
      margin: auto 0;
      max-height: 44px;
      max-width: 44px;
    }
  }
}

.v-tooltip__content {
  background-color: $background !important;
  font: 12px/16px MontserratMedium !important;
  box-shadow: 0px 4px 8px $shadow-green !important;

  .nomination-names {
    color: $post-todo;
  }
  .like-names {
    color: $text-color;
  }
}

.nominations-list {
  width: 60%;
  padding: 20px;
}

.nominations {
  max-height: 280px;
  overflow-y: auto;
  overflow-x: auto;
}

.nominations::-webkit-scrollbar {
  width: 4px;
  height: 80%;
}

.nominations::-webkit-scrollbar-track {
  background: $disabled;
  border-radius: 12px;
}

.nominations::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 12px;
}

.nomination-item {
  display: flex;
  gap: 16px;

  .nomination {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    h1 {
      font: 20px/24px MontserratRegular;
      color: $primary;
    }
    p {
      text-align: left;
      letter-spacing: 1px;
    }
  }
}

@media screen and (max-width: $sm) {
  .modal {
    width: 100%;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: auto;
    word-break: break-all;
    height: auto;
    gap: 10px;

    .separator {
      width: 80%;
    }
  }

  .nominations-list {
    height: 60%;
    width: 80%;
  }

  .nomination-item {
    gap: 10px;
    word-break: normal;

    .nomination {
      h1 {
        font: 16px/20px MontserratRegular;
      }
    }
  }
}
</style>
