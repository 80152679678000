<template>
  <v-container fluid>
    <div class="text-center">
      <div class="winners-text">
        <p>
          {{ $t('views.winners.see_winners') }}
        </p>
      </div>
    </div>
    <div class="winners-container">
      <winner-card
        v-for="winner in getValhallaWinners"
        :key="winner.id"
        :name="winner.name"
        :photo-url="winner.photo_url"
        :nominations-received="winner.nominations_received"
        :likes="winner.nomination_likes"
        :final-count="winner.final_count"
      />
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import WinnerCard from '../components/globals/WinnerCard.vue';

export default {
  name: 'Winners',
  components: {
    WinnerCard,
  },
  computed: {
    ...mapGetters(['getValhallaWinners']),
  },
  mounted() {
    this.fetchValhallaWinners();
  },
  methods: {
    ...mapActions(['fetchValhallaWinners']),
  },
};
</script>

<style lang="scss">
.winners-text {
  height: 44px;
  font: 600 34px/36px MontserratRegular;
  color: $primary;
}

.winners-container {
  /* fits at most 6 cards per row */
  max-width: 1700px;

  margin: 48px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, 240px);
  grid-gap: 28px;
  justify-content: center;
}

.valhalla-btn {
  position: absolute;
  bottom: 20px;
}

@media screen and (max-width: $sm) {
  .winners-container {
    max-width: 260px;

    margin: 70px auto;
    display: flex;
    flex-direction: column
  }
}
</style>
