<template>
  <v-card
    class="text-center winner-card d-flex align-center justify-center flex-column pa-3 rounded-lg"
    @click="showModal = true"
  >
    <v-avatar
      min-width="56px"
      height="56px"
    >
      <v-img
        alt="Avatar"
        :src="photoUrl"
      />
    </v-avatar>

    <v-card-title class="indicated-title">
      {{ name }}
    </v-card-title>

    <div class="indications-container">
      <v-img
        class="icon"
        src="../../assets/img/star-green.svg"
      />
      <p>
        {{ finalCount }}
      </p>
    </div>
    <WinnerModal
      v-if="showModal"
      :name="name"
      :photo-url="photoUrl"
      :nominations-received="nominationsReceived"
      :likes="likes"
      :final-count="finalCount"
      @close-modal="showModal = false"
    />
  </v-card>
</template>

<script>
import WinnerModal from './WinnerModal.vue';

export default {
  name: 'WinnerCard',
  components: {
    WinnerModal,
  },
  props: {
    name: {
      type: String,
      default: '() => {}',
    },
    photoUrl: {
      type: String,
      default: '() => {}',
    },
    nominationsReceived: {
      type: Array,
      default: () => [],
    },
    likes: {
      type: Array,
      default: () => [],
    },
    finalCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.winner-card {
  height: 206px;
  min-width: 240px;
  .indicated-title {
    font: 16px/24px MontserratBold;
    letter-spacing: 0.14px;
    white-space: nowrap;
  }

  .indications-container {
    justify-content: center;
    display: flex;
    width: 55%;
    p {
      font: 16px/24px MontserratBold;
      margin: auto 0;
    }
    .icon {
      margin: auto 0;
      max-height: 44px;
      max-width: 44px;
    }
  }
}
</style>
